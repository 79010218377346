import { ReplenishmentUnifiedInventoryResponse } from "@deliverr/replenishment-client";
import { chain, floor, sumBy } from "lodash";

export const doesKittedProductHaveInventoryInWarehouse = (
  unifiedInventory: ReplenishmentUnifiedInventoryResponse | undefined,
  warehouseId: string,
  packSku: string
): boolean => {
  const maxQty = getKitInventoryInWarehouse(unifiedInventory, warehouseId, packSku);
  return maxQty > 0;
};

export const getKitInventoryInWarehouse = (
  unifiedInventory: ReplenishmentUnifiedInventoryResponse | undefined,
  warehouseId: string,
  packSku: string
): number => {
  const kitComponentBreakdown = unifiedInventory?.kitComponentBreakdown;
  const warehouseInventory = unifiedInventory?.rawInventoryBreakdown[warehouseId];

  return (
    chain(kitComponentBreakdown?.[packSku])
      .values()
      .map(({ componentQuantityInKit, kitComponentPacks }) => {
        return floor(
          sumBy(
            kitComponentPacks,
            ({ dsku, caseQty }) =>
              (floor((warehouseInventory?.[dsku]?.availableQty ?? 0) / (caseQty ?? 1)) * (caseQty ?? 1)) /
              componentQuantityInKit
          )
        );
      })
      .min()
      .value() ?? 0
  );
};

export const getProductInventoryInWarehouse = (
  unifiedInventory: ReplenishmentUnifiedInventoryResponse | undefined,
  warehouseId: string,
  dsku: string
): number => {
  const warehouseInventory = unifiedInventory?.rawInventoryBreakdown[warehouseId];
  return (
    (warehouseInventory?.[dsku]?.availableQty ?? 0) + (warehouseInventory?.[dsku]?.productInventory?.availableQty ?? 0)
  );
};

export const doesProductHaveInventoryInWarehouse = (
  unifiedInventory: ReplenishmentUnifiedInventoryResponse | undefined,
  warehouseId: string,
  dsku: string
): boolean => getProductInventoryInWarehouse(unifiedInventory, warehouseId, dsku) > 0;
