import { defineMessages } from "react-intl";

export const ReplenishmentWarehouseSelectLabels = defineMessages({
  insufficientInventoryWarehouse: {
    id: "replenishment.createTransfer.warehouseSelection.insifficientInventoryWarehouse",
    defaultMessage: "{skusWithAvailableInventory} of {totalSkus} selected products have inventory in this warehouse",
  },
  completeInventoryWarehouse: {
    id: "replenishment.createTransfer.warehouseSelection.completeInventoryWarehouse",
    defaultMessage: "All selected products have inventory",
  },
  selectWarehouse: {
    id: "replenishment.createTransfer.warehouseSelection.selectWarehouse",
    defaultMessage: "Select warehouse",
  },
  outOfStockProductError: {
    id: "replenishment.createTransfer.warehouseSelection.outOfStockProductError",
    defaultMessage:
      "This product is out of stock in the selected warehouse. Please remove it or select another warehouse to continue.",
  },
  availableInventoryLessThanCaseQtyError: {
    id: "replenishment.createTransfer.warehouseSelection.availableInventoryLessThanCaseQtyError",
    defaultMessage:
      "The available inventory in the selected warehouse is less than the case quantity. Please remove this product or reach out to support.",
  },
});
