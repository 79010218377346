import React, { FC } from "react";
import { Pill, PillColors } from "common/components/Pill";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";
import {
  ShipmentStatusGrouping,
  SHIPMENT_STATUS_DISPLAY_TEXT,
  SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT,
} from "inbounds/ShipmentStatus";
import { useIntl } from "react-intl";
import { useSmbAccelerateFeatureOn } from "common/featureFlags";
import { FeatureName } from "common/Split";
import { getShouldShowInProgressStatus } from "./utils/getShouldShowInProgressStatus";
import { FlexportShipmentStatus, flexportStatusPillColors } from "../../ShipmentDetails/FlexportShipmentStatus";
import { getFlexportShipmentStatusText } from "../../ShipmentDetails/ShipmentInfoSections/ShipmentStatusTitle/getFlexportShipmentStatusText";

const inboundShipmentStatusToPillColor = {
  [InboundShipmentStatus.NEED_PACKAGE_DETAILS]: PillColors.GRAY,
  [InboundShipmentStatus.PACKAGES_ADDED]: PillColors.GRAY,
  [InboundShipmentStatus.PACKAGES_FINAL]: PillColors.GRAY,
  [InboundShipmentStatus.READY_FOR_WAREHOUSE]: PillColors.BLUE,
  [InboundShipmentStatus.IN_TRANSIT]: PillColors.BLUE,
  [InboundShipmentStatus.CROSS_DOCKING]: PillColors.YELLOW,
  [InboundShipmentStatus.IN_PREP]: PillColors.PURPLE,
  [InboundShipmentStatus.FORWARDING]: PillColors.PURPLE,
  [InboundShipmentStatus.ARRIVED]: PillColors.PURPLE,
  [InboundShipmentStatus.RECEIVING]: PillColors.PURPLE,
  [InboundShipmentStatus.COMPLETED]: PillColors.GREEN,
  [InboundShipmentStatus.WAREHOUSE_APPOINTMENT_NEEDED]: PillColors.RED,
};

interface ShipmentStatusPillProps {
  className?: string;
  shipmentStatus: InboundShipmentStatus;
  bookingSubmitted?: boolean;
  bookingType?: string;
  bookingStatus?: FlexportShipmentStatus;
}

export const ShipmentStatusPill: FC<ShipmentStatusPillProps> = ({
  className,
  shipmentStatus,
  bookingType,
  bookingSubmitted,
  bookingStatus,
}) => {
  const { formatMessage } = useIntl();
  const isBookingStatusFeatureOn = useSmbAccelerateFeatureOn(FeatureName.SmbAccelerateBookingStatus);
  const message =
    isBookingStatusFeatureOn && bookingStatus
      ? getFlexportShipmentStatusText(bookingStatus)
      : getInboundShipmentStatusText(shipmentStatus, bookingType, bookingSubmitted);
  const pillColor =
    isBookingStatusFeatureOn && bookingStatus
      ? flexportStatusPillColors[bookingStatus]
      : getInboundShipmentStatusPillColor(shipmentStatus, bookingSubmitted, bookingType);
  return (
    <Pill className={className} color={pillColor}>
      {formatMessage(message)}
    </Pill>
  );
};

const getInboundShipmentStatusPillColor = (
  shipmentStatus: InboundShipmentStatus,
  bookingSubmitted?: boolean,
  bookingType?: string
) => {
  return getShouldShowInProgressStatus(shipmentStatus, bookingSubmitted, bookingType)
    ? PillColors.BLUE
    : inboundShipmentStatusToPillColor[shipmentStatus];
};

const getInboundShipmentStatusText = (
  status: InboundShipmentStatus,
  bookingType?: string,
  bookingSubmitted?: boolean
) => {
  if (getShouldShowInProgressStatus(status, bookingSubmitted, bookingType)) {
    return {
      defaultMessage: "In progress",
      id: `shipmentStatusGrouping.InProgress`,
    };
  } else {
    return (
      SHIPMENT_STATUS_DISPLAY_TEXT[status] ?? SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[ShipmentStatusGrouping.IN_DRAFT]
    );
  }
};
