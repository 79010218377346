import { KitComponentOrderItemDto } from "@deliverr/replenishment-client";
import { SelectedProductData } from "common/components/ProductChooser";
import { saveSearchedKittedProductDetails } from "common/components/ProductChooser/ProductChooserActions";
import { fetchAvailableToKitQty } from "common/components/ProductSearch/Kit/fetchAvailableToKitQty";
import { SPThunkAction } from "common/ReduxUtils";
import { addKittedProduct } from "transfers/create/store/addKittedProduct";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";

export const loadKittedProducts =
  (selectedProducts: { [dsku: string]: SelectedProductData }, hasKittedComponentSaved: boolean): SPThunkAction =>
  async (dispatch, getState): Promise<KitComponentOrderItemDto[] | undefined> => {
    for (const data of Object.values(selectedProducts)) {
      // for...of to run fetchAvailableToKitQty in sync
      const { potentialInventory, kitComponents, availableInventoryMap } = await fetchAvailableToKitQty(data);
      dispatch(
        saveSearchedKittedProductDetails(data.dsku, {
          kitComponents,
          availableInventoryMap,
          availableToKit: potentialInventory,
        })
      );
      if (!hasKittedComponentSaved) {
        dispatch(addKittedProduct(data));
      }
    }

    const { kitComponentOrderItems } = selectTransferCreate(getState());
    return kitComponentOrderItems;
  };
