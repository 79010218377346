import { defineMessages, MessageDescriptor } from "react-intl";
import { DimensionsError } from "./DimensionsError";

import {
  INBOUND_SPD_MAX_BOX_LONGEST,
  INBOUND_SPD_MAX_BOX_MIDDLE,
  INBOUND_SPD_MAX_BOX_SHORTEST,
  INBOUND_SPD_MAX_BOX_WEIGHT,
  INBOUND_SPD_SIOC_MIN_BOX_LONGEST,
  INBOUND_SPD_SIOC_MIN_BOX_MIDDLE,
  INBOUND_SPD_SIOC_MIN_BOX_SHORTEST,
  INBOUND_SPD_SIOC_MIN_BOX_WEIGHT,
} from "inbounds/constants/spdConstants";
import { STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS } from "storage/inbounds/CaseDimensionsConstants";
import { getInboundCaseDimenLimit } from "common/utils/product/composeGetIsOverDimensions";

export const getDimensionErrorLabel = (
  error: DimensionsError,
  isDimensionOverrideFeatureEnabled: boolean,
  isIntlDtc?: boolean
): MessageDescriptor => {
  const errorLabels = defineMessages({
    [DimensionsError.INCOMPLETE]: {
      id: "inbounds.dimensionsAndWeights.incompleteError",
      defaultMessage: "Incomplete SKU measurements cannot be submitted.",
    },
    [DimensionsError.SIOC]: {
      id: "inbounds.dimensionsAndWeights.siocError",
      defaultMessage: `Items greater than {maxLength}" x {maxWidth}" x {maxHeight}" or over {maxWeight} lbs must be shipped using the seller's own customer container (SIOC).`,
      values: {
        maxLength: INBOUND_SPD_SIOC_MIN_BOX_LONGEST,
        maxWidth: INBOUND_SPD_SIOC_MIN_BOX_MIDDLE,
        maxHeight: INBOUND_SPD_SIOC_MIN_BOX_SHORTEST,
        maxWeight: INBOUND_SPD_SIOC_MIN_BOX_WEIGHT,
      },
    },
    [DimensionsError.OVER_DIMENSIONS]: {
      id: "inbounds.dimensionsAndWeights.oversizedError",
      defaultMessage: `Items greater than {maxLength}" x {maxWidth}" x {maxHeight}" cannot be fulfilled by Flexport.`,
      values: {
        maxLength: INBOUND_SPD_MAX_BOX_LONGEST,
        maxWidth: INBOUND_SPD_MAX_BOX_MIDDLE,
        maxHeight: INBOUND_SPD_MAX_BOX_SHORTEST,
      },
    },
    [DimensionsError.OVER_WEIGHT]: {
      id: "inbounds.dimensionsAndWeights.overweightError",
      defaultMessage: "Items over {maxWeight} lbs cannot be fulfilled by Flexport.",
      values: {
        maxWeight: INBOUND_SPD_MAX_BOX_WEIGHT,
      },
    },
    [DimensionsError.OVER_DIMENSIONS_AND_WEIGHT]: {
      id: "inbounds.dimensionsAndWeights.oversizedAndOverweightError",
      defaultMessage: `Items greater than {maxLength}" x {maxWidth}" x {maxHeight}" or over {maxWeight} lbs cannot be fulfilled by Flexport.`,
      values: {
        maxLength: INBOUND_SPD_MAX_BOX_LONGEST,
        maxWidth: INBOUND_SPD_MAX_BOX_MIDDLE,
        maxHeight: INBOUND_SPD_MAX_BOX_SHORTEST,
        maxWeight: INBOUND_SPD_MAX_BOX_WEIGHT,
      },
    },
    [DimensionsError.STORAGE_OVER_DIMENSIONS]: {
      id: "storage.inbounds.dimensionsAndWeights.oversizedError",
      defaultMessage: `Items greater than {maxLength}" x {maxWidth}" x {maxHeight}" cannot be fulfilled by Flexport.`,
      values: (() => {
        const dimen = getInboundCaseDimenLimit(isDimensionOverrideFeatureEnabled, isIntlDtc);
        return {
          maxLength: dimen.maxLongestSide,
          maxWidth: dimen.maxMiddleSide,
          maxHeight: dimen.maxShortestSide,
        };
      })(),
    },
    [DimensionsError.STORAGE_OVER_WEIGHT]: {
      id: "storage.inbounds.dimensionsAndWeights.overweightError",
      defaultMessage: "Items over {maxWeight} lbs cannot be fulfilled by Flexport.",
      values: {
        maxWeight: STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS,
      },
    },
    [DimensionsError.STORAGE_OVER_DIMENSIONS_AND_WEIGHT]: {
      id: "storage.inbounds.dimensionsAndWeights.oversizedAndOverweightError",
      defaultMessage: `Items greater than {maxLength}" x {maxWidth}" x {maxHeight}" or over {maxWeight} lbs cannot be fulfilled by Flexport.`,
      values: (() => {
        const dimen = getInboundCaseDimenLimit(isDimensionOverrideFeatureEnabled, isIntlDtc);
        return {
          maxLength: dimen.maxLongestSide,
          maxWidth: dimen.maxMiddleSide,
          maxHeight: dimen.maxShortestSide,
          maxWeight: STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS,
        };
      })(),
    },
    [DimensionsError.INVALID_DIMENSIONS_AND_WEIGHT]: {
      id: "storage.inbounds.dimensionsAndWeights.invalidDimensionsAndWeight",
      defaultMessage: "Please enter correct box dimensions to avoid issues during shipping from reserve storage.",
    },
  });

  return errorLabels[error];
};
