import { BoldWarehouseName, ReplenishmentWarehouseSelectOption } from "./ReplenishmentWarehouseSelectOption";
import { OptionProps as SelectOptionProps } from "@deliverr/react-select-v1";
import { useDispatch, useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import {
  transferCreateSetSelectedWarehouseId,
  transferCreateUpdateProductErrorMessages,
  transferCreateUpdateProductWarehouseInventory,
} from "transfers/create/actions";
import { useLoader } from "common/components/WithLoader/useLoader";
import { SimpleWarehouse } from "@deliverr/business-types";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { chain, isEmpty, isNil, map, values } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { ReplenishmentWarehouseSelectLabels } from "./ReplenishmentWarehouseSelectLabels";
import { useIntl } from "react-intl";
import {
  doesKittedProductHaveInventoryInWarehouse,
  doesProductHaveInventoryInWarehouse,
  getKitInventoryInWarehouse,
  getProductInventoryInWarehouse,
} from "./ReplenishmentWarehouseSelectionUtils";

export interface ReplenishmentWarehouseOption {
  value: {
    warehouse: SimpleWarehouse;
    totalSkus: number;
    skusWithAvailableInventory: number;
  };
  label: SimpleWarehouse["id"];
}

export const useReplenishmentWarehouseSelect = () => {
  const {
    selectedProducts,
    warehouseSelection: { unifiedInventory, selectedWarehouseId },
  } = useSelector(selectTransferCreate);

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const isWarehouseSelectionLoading = useLoader(CreateTransferLoader.FetchStorageInventoryLoader);
  const totalSkus = chain(selectedProducts).keys().compact().value().length;

  const warehouseOptions = useMemo(
    () =>
      map(unifiedInventory?.warehousesWithInventory, (warehouse) => {
        const warehouseId = warehouse.id;

        return {
          value: {
            warehouse,
            totalSkus,
            skusWithAvailableInventory: chain(selectedProducts)
              .values()
              .filter(({ dsku, isKit }) =>
                isKit
                  ? doesKittedProductHaveInventoryInWarehouse(unifiedInventory, warehouseId, dsku)
                  : doesProductHaveInventoryInWarehouse(unifiedInventory, warehouseId, dsku)
              )
              .value().length,
          },
          label: warehouse.id,
        };
      }),
    [unifiedInventory, selectedProducts, totalSkus]
  );

  const selectedWarehouseOption = warehouseOptions.find(({ value }) => value.warehouse.id === selectedWarehouseId);

  const formatOptionLabel = (option) => {
    const warehouse: SimpleWarehouse | undefined = option?.value?.warehouse;
    return !isNil(warehouse) ? <BoldWarehouseName warehouse={warehouse} /> : null;
  };

  const customComponents = {
    Option: (props: SelectOptionProps) => <ReplenishmentWarehouseSelectOption {...props} />,
  };

  const refreshProductInventories = useCallback(
    (warehouseId: string) => {
      const dskuToInventoryMap: { [dsku: string]: number } = {};
      const dskuToErrorMessageMap: { [dsku: string]: string | undefined } = {};

      values(selectedProducts).forEach((product) => {
        const availableInventory = product.isKit
          ? getKitInventoryInWarehouse(unifiedInventory, warehouseId, product.dsku)
          : getProductInventoryInWarehouse(unifiedInventory, warehouseId, product.dsku);

        dskuToInventoryMap[product.dsku] = availableInventory;

        dskuToErrorMessageMap[product.dsku] =
          availableInventory === 0
            ? formatMessage(ReplenishmentWarehouseSelectLabels.outOfStockProductError)
            : undefined;
      });

      dispatch(transferCreateUpdateProductWarehouseInventory(dskuToInventoryMap));
      dispatch(transferCreateUpdateProductErrorMessages(dskuToErrorMessageMap));
    },
    [selectedProducts, dispatch, unifiedInventory, formatMessage]
  );

  const onWarehouseChange = useCallback(
    ({ value }: { value: ReplenishmentWarehouseOption["value"] }) => {
      dispatch(transferCreateSetSelectedWarehouseId(value.warehouse.id));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isEmpty(unifiedInventory?.warehousesWithInventory) && !isWarehouseSelectionLoading) {
      !isNil(selectedWarehouseId)
        ? refreshProductInventories(selectedWarehouseId)
        : onWarehouseChange({ value: warehouseOptions[0].value });
    }
  }, [
    selectedWarehouseId,
    unifiedInventory,
    isWarehouseSelectionLoading,
    refreshProductInventories,
    onWarehouseChange,
    warehouseOptions,
  ]);

  return {
    isWarehouseSelectionLoading,
    selectedWarehouseOption,
    warehouseOptions,
    customComponents,
    onWarehouseChange,
    formatOptionLabel,
  };
};
