import { Title, Stack, Input, Button, Notification, Text, Box, ThemeProps } from "common/components/ui";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import WithLoader from "../WithLoader";
import { NewProductCasePackFormValues, useNewProductCasePackForm } from "./useNewProductCasePackForm";
import { ProductCasePackData } from "@deliverr/commons-clients/lib/product/ProductCasePack";
import { getProductSku } from "inbounds/steps/ship/utils/getProductSku";
import styled from "@emotion/styled";
import { CasePackMeasurements } from "./CasePackMeasurements";
import { ProductDisplayData } from "../ProductCasePackSelect/ProductCasePackSelect.types";

const LightText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
`
);

const FormInput = styled(Input)<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S3} !important;
`
);

const FormTitle = styled(Title)(`
  align-self: center;
`);

const FormButton = styled(Button)(`
  width: 48% !important;
`);

const ButtonContainer = styled.div(`
  display: flex;
  justify-content: space-between;
`);

const StyledNotification = styled(Notification)<ThemeProps>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S7};
  margin-top: ${theme.spacing.S2};
`
);

interface NewProductCasePackFormProps {
  product: ProductDisplayData;
  defaultFormValues?: NewProductCasePackFormValues;
  isIntlDtc?: boolean;
  onNewCasePackFormSubmit?: (productCasePackData: ProductCasePackData) => void;
  onCancel?: () => void;
}

export const NewProductCasePackForm: FC<NewProductCasePackFormProps> = ({
  product,
  defaultFormValues,
  isIntlDtc,
  onCancel,
  onNewCasePackFormSubmit,
}) => {
  const {
    caseLength,
    caseHeight,
    caseWeight,
    caseWidth,
    packName,
    caseQty,
    onFormSubmit,
    isSubmitDisabled,
    newProductCasePackLoader,
    packDimensionsError,
  } = useNewProductCasePackForm(product.dsku, defaultFormValues, onNewCasePackFormSubmit, isIntlDtc);
  return (
    <Stack>
      <FormTitle as="h2" displayAs="h4">
        <FormattedMessage id="product.newProductCasePack.title" defaultMessage="Add case pack template" />
      </FormTitle>

      <Box margin-top="S5" margin-bottom="S5">
        <Text bold color="black">
          {product.name}
        </Text>
        <LightText>{getProductSku(product)}</LightText>
      </Box>

      <form onSubmit={onFormSubmit} autoComplete="off">
        <Stack>
          <FormInput
            type="number"
            {...caseQty.field}
            data-testid="new-product-case-pack-caseQty-input"
            label={<FormattedMessage id="product.newProductCasePack.packName" defaultMessage="Units per box *" />}
            min={1}
            helpText={
              <FormattedMessage
                id="product.newProductCasePack.caseQty.helpText"
                defaultMessage="This is the number of sellable units in each box."
              />
            }
            errorMessage={
              <FormattedMessage
                id="product.newProductCasePack.caseQty.validationError"
                defaultMessage="The units per box must be a positive value under 1,000,000"
              />
            }
            hasError={caseQty.fieldState.invalid}
          />
          <CasePackMeasurements
            caseHeight={caseHeight}
            caseLength={caseLength}
            caseWeight={caseWeight}
            caseWidth={caseWidth}
            packDimensionsError={packDimensionsError}
            isIntlDtc={isIntlDtc}
          />
          <FormInput
            {...packName.field}
            label={<FormattedMessage id="product.newProductCasePack.packName" defaultMessage="Template name *" />}
            errorMessage={
              <FormattedMessage
                id="product.newProductCasePack.packName.validationError"
                defaultMessage="The template name cannot be empty or exceed 50 characters"
              />
            }
            hasError={packName.fieldState.invalid}
          />
          <div>
            <StyledNotification
              appearance="WARNING"
              content={{
                description: (
                  <FormattedMessage
                    id="product.newProductCasePack.caseQty.callout"
                    defaultMessage="Case pack templates cannot be changed later. Make sure this information is accurate."
                  />
                ),
              }}
            />
            <ButtonContainer>
              <FormButton data-testid="new-product-case-pack-cancel" secondary onClick={onCancel}>
                <FormattedMessage id="product.newProductCasePack.cancel.button" defaultMessage="Cancel" />
              </FormButton>

              <FormButton type="submit" disabled={isSubmitDisabled} data-testid="new-product-case-pack-submit">
                <WithLoader name={newProductCasePackLoader} size="lg">
                  <FormattedMessage
                    id="product.newProductCasePack.submit.button"
                    defaultMessage={isIntlDtc ? "Add" : "Save"}
                  />
                </WithLoader>
              </FormButton>
            </ButtonContainer>
          </div>
        </Stack>
      </form>
    </Stack>
  );
};
