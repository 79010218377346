import { PrepJobResponseStatus } from "@deliverr/prep-service-client";
import { MessageDescriptor, defineMessages } from "react-intl";

export const PREP_STATUS_PILL_LABEL: Record<PrepJobResponseStatus, MessageDescriptor> = defineMessages({
  [PrepJobResponseStatus.INITIALIZED]: {
    id: "prep.prepStatusPill.initialized",
    defaultMessage: "Created",
  },
  [PrepJobResponseStatus.ARRIVED]: {
    id: "prep.prepStatusPill.arrived",
    defaultMessage: "Created",
  },
  [PrepJobResponseStatus.RECEIVED]: {
    id: "prep.prepStatusPill.received",
    defaultMessage: "Received",
  },
  [PrepJobResponseStatus.IN_PROGRESS]: {
    id: "prep.prepStatusPill.inProgress",
    defaultMessage: "In progress",
  },
  [PrepJobResponseStatus.COMPLETED]: {
    id: "prep.prepStatusPill.completed",
    defaultMessage: "Complete",
  },
  [PrepJobResponseStatus.BILLED]: {
    id: "prep.prepStatusPill.billed",
    defaultMessage: "Complete",
  },
  [PrepJobResponseStatus.ARCHIVED]: {
    id: "prep.prepStatusPill.archived",
    defaultMessage: "Archived",
  },
  [PrepJobResponseStatus.DRAFT]: {
    id: "prep.prepStatusPill.draft",
    defaultMessage: "Draft",
  },
});
