import { Product } from "@deliverr/commons-clients";
import { hasDimensionsAndWeight } from "common/utils/product/hasDimensionsAndWeight";
import { hasValidDimensionsAndWeight } from "common/utils/product/hasValidDimensionsAndWeight";
import { composeGetIsOverWeight } from "common/utils/product/composeGetIsOverWeight";
import { composeGetIsOverDimensions, getInboundCaseDimenLimit } from "common/utils/product/composeGetIsOverDimensions";
import { ProductDimensionsValidator } from "inbounds/steps/dimensions/DimensionsAndWeightsTable/ProductDimensionsValidator";
import { STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS } from "../CaseDimensionsConstants";
import { DimensionsError } from "inbounds/steps/dimensions/DimensionsAndWeightsTable/DimensionsError";

const validateIfInvalidProduct = (
  isProductOverSized: boolean,
  isProductOverWeight: boolean,
  product: Partial<Product>
): DimensionsError | undefined => {
  if (isProductOverSized && isProductOverWeight) {
    return DimensionsError.STORAGE_OVER_DIMENSIONS_AND_WEIGHT;
  } else if (isProductOverSized) {
    return DimensionsError.STORAGE_OVER_DIMENSIONS;
  } else if (isProductOverWeight) {
    return DimensionsError.STORAGE_OVER_WEIGHT;
  } else if (!hasDimensionsAndWeight(product)) {
    return DimensionsError.INCOMPLETE;
  } else if (!hasValidDimensionsAndWeight(product)) {
    return DimensionsError.INVALID_DIMENSIONS_AND_WEIGHT;
  }

  return;
};

export const getCaseDimensionsError: ProductDimensionsValidator = (
  product: Partial<Product>,
  isDimensionOverrideFeatureEnabled?: boolean,
  isIntlDtc?: boolean
) => {
  const isProductOverSized = composeGetIsOverDimensions(getInboundCaseDimenLimit(false, isIntlDtc))(product);
  const isProductOverWeight = composeGetIsOverWeight(STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS)(product);
  if (isDimensionOverrideFeatureEnabled) {
    const isProductOverSizedAfterRelaxation = composeGetIsOverDimensions(getInboundCaseDimenLimit(true, isIntlDtc))(
      product
    );
    return validateIfInvalidProduct(isProductOverSizedAfterRelaxation, isProductOverWeight, product);
  }

  return validateIfInvalidProduct(isProductOverSized, isProductOverWeight, product);
};
