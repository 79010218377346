import { useCallback, useState } from "react";
import { SelectedProductData } from "common/components/ProductChooser";
import { compact, find, isEmpty, isNil, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  transferBulkAddProduct,
  transferCreateAddProduct,
  transferCreateRemoveProduct,
  transferCreateResetProductSelection,
  transferCreateSetIsEachPickingOrderFlag,
  transferCreateUpdateProductErrorMessage,
} from "transfers/create/actions";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { useFeatureOn, FeatureName } from "common/Split";
import { getDistributionChannel } from "transfers/create/actions/replenishmentOrder/utils/getDistributionChannel";
import { DskuQty } from "common/clients/nonCompliance/BoxLabelIncidentDsku/DskuQty";
import {
  BulkUploadValidationError,
  UploadItem,
  extractBulkUploadValidationErrors,
} from "components/BulkUpload/useBulkUploadModal";
import { BulkUploadError } from "components/BulkUpload/BulkUploadError";
import { DistributionChannel, EntityError, EntityName, ProductCasePackWithATP } from "@deliverr/replenishment-client";
import { addKittedProduct } from "transfers/create/store/addKittedProduct";
import { getProductChooserKittedDSKUDetails } from "common/components/ProductChooser/ProductChooserSelector";
import { loadKittedProducts } from "../common/replenishment/loadKittedProducts";
import { removeKittedProduct } from "transfers/create/store/removeKittedProduct";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { useLoader } from "common/components/WithLoader/useLoader";
import { getReplenishmentUnifiedInventory } from "transfers/create/steps/common/replenishment/warehouse-selection/actions/getReplenishmentUnifiedInventory";
import { getErrorForProductFn } from "../getErrorForProductFn";
import { useIntl } from "react-intl";

export const useTransferPickProductStep = () => {
  const dispatch = useDispatch();
  const { selectedProducts, destinationType, isEachPickingOrder, warehouseSelection } =
    useSelector(selectTransferCreate);
  const kitDskuDetails = useSelector(getProductChooserKittedDSKUDetails);
  const dskuQuantities: DskuQty[] = Object.keys(selectedProducts).map((dsku) => ({ dsku }));
  const [shouldShowModal, setShowModal] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  const isMultiCasePackEnabled = useFeatureOn(FeatureName.StorageMultiCasePack);
  const isFbaV3On = useFeatureOn(FeatureName.ReplenishmentFbaV3);

  const isInventoryLoading = useLoader(CreateTransferLoader.FetchStorageInventoryLoader);
  const isPrepKittingEnabled = useFeatureOn(FeatureName.PrepKittingRSToDTCEnabled);

  const isNextDisabled =
    isEmpty(selectedProducts) ||
    isInventoryLoading ||
    !!find(selectedProducts, (val) => !isEmpty(val.errorMessage)) ||
    isNil(warehouseSelection.selectedWarehouseId);

  const distributionChannel = getDistributionChannel(destinationType, isFbaV3On);
  const isWholesaleOrderEachPickingOn = useFeatureOn(FeatureName.WholesaleOrderEachPicking);
  const isEachPickingEnabled = distributionChannel === DistributionChannel.WHOLESALE && isWholesaleOrderEachPickingOn;
  const isNextStepLoading = useLoader(CreateTransferLoader.NextStepLoader);

  const isKitOrderUploadEnabled =
    isPrepKittingEnabled && distributionChannel === DistributionChannel.ECOM_VIA_CROSSDOCK;
  const [isKitOrderEnabled, setIsKitOrderEnabled] = useState(isKitOrderUploadEnabled);

  const addProduct = (dsku: string, product: SelectedProductData) => {
    const dskuList = [...dskuQuantities, { dsku }];
    const dskus = dskuList.map((item) => item.dsku);

    if (isPrepKittingEnabled && product.isKit && product.caseQty === 1) {
      // We will allow kitting only for the products with case pack qty 1
      dispatch(addKittedProduct(product));
    } else {
      const productWithoutInventory = {
        ...product,
        storageOnHandQty: undefined,
        storageUnfilledOrderQty: undefined,
        storageUnavailableQty: undefined,
      };
      dispatch(transferCreateAddProduct(dsku, productWithoutInventory));
    }

    dispatch(getReplenishmentUnifiedInventory(dskus));
    dispatch(
      transferCreateUpdateProductErrorMessage(dsku, getErrorForProductFn(product, formatMessage, distributionChannel))
    );
  };

  const removeProduct = (dsku: string) => {
    if (isPrepKittingEnabled && kitDskuDetails?.[dsku]) {
      dispatch(removeKittedProduct(dsku, dskuQuantities));
    } else {
      dispatch(transferCreateRemoveProduct(dsku));
    }

    const dskus = dskuQuantities.filter((dskuQuantity) => dskuQuantity.dsku !== dsku).map((item) => item.dsku);

    if (!isEmpty(dskus)) {
      dispatch(getReplenishmentUnifiedInventory(dskus));
    }
  };

  const handleKitReplenishmentBulkUploadSuccess = useCallback(
    async (inputProducts) => {
      // Type is different as per bulk upload usecases. Kitting details has just enough that is required.
      await dispatch(loadKittedProducts(inputProducts, false));
    },
    [dispatch]
  );

  const handleReplenishmentBulkUploadSuccess = useCallback(
    async (inputProducts: (ProductCasePackWithATP & UploadItem)[]) => {
      if (!inputProducts) {
        return;
      }

      dispatch(transferCreateResetProductSelection());

      if (isKitOrderUploadEnabled && isKitOrderEnabled) {
        await handleKitReplenishmentBulkUploadSuccess(inputProducts);
      } else {
        let skuProductMap = {};

        const productsWithoutInventory: UploadItem[] = [];

        for (const product of inputProducts) {
          skuProductMap = {
            ...skuProductMap,
            [product.dsku]: {
              ...product,
              storageOnHandQty: product.onHandQty,
              storageUnfilledOrderQty: product.unfilledOrderQty,
              storageUnavailableQty: product.unavailableQty,
              objectID: product.dsku,
            },
          };
        }

        if (!isEmpty(productsWithoutInventory)) {
          throw new EntityError({
            code: BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE,
            entity: EntityName.REPORT,
            message: "Inventory unavailable for selected products in CSV",
            errors: productsWithoutInventory.map(
              (product) =>
                new EntityError({
                  code: BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE,
                  entity: EntityName.ORDER_ITEM,
                  entityId: product.msku,
                  payload: product,
                })
            ),
          });
        }

        dispatch(transferBulkAddProduct(skuProductMap));
      }

      const dskuList = inputProducts.map(({ dsku }) => dsku);
      dispatch(getReplenishmentUnifiedInventory(dskuList));
    },
    [dispatch, handleKitReplenishmentBulkUploadSuccess, isKitOrderEnabled, isKitOrderUploadEnabled]
  );

  const handleProductImportFailure = (err: any, setValidationErrors: (errors: BulkUploadValidationError[]) => void) => {
    if (err instanceof EntityError && err.code === BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE) {
      setValidationErrors(
        extractBulkUploadValidationErrors({
          [BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE]: {
            cells: compact(map(err.errors, ({ entityId }) => entityId as string)),
            isWholeSheetError: false,
            error: BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE,
          },
        } as Record<BulkUploadError, BulkUploadValidationError>)
      );
    } else {
      throw err;
    }
  };

  const handleEachPickingToggle = async () => {
    await dispatch(transferCreateSetIsEachPickingOrderFlag(!isEachPickingOrder));
    const dskuList = dskuQuantities.map(({ dsku }) => dsku);

    if (!isEmpty(dskuList)) {
      dispatch(getReplenishmentUnifiedInventory(dskuList));
    }
  };

  const handleKitOrderToggle = () => {
    setIsKitOrderEnabled(!isKitOrderEnabled);
  };

  const switchConfig = isEachPickingEnabled
    ? {
        checked: !!isEachPickingEnabled,
        onChange: handleEachPickingToggle,
        labelPath: transfersShipmentCreateLabels.steps.product.eachPicking,
      }
    : {
        checked: !!isKitOrderEnabled,
        onChange: handleKitOrderToggle,
        labelPath: transfersShipmentCreateLabels.steps.product.kitOrderEnabled,
      };

  const shouldShowEachesTemplate = !!(isEachPickingEnabled && isEachPickingOrder) || isKitOrderEnabled;

  return {
    isEachPickingEnabled,
    isEachPickingOrder,
    isKitOrderEnabled,
    isKitOrderUploadEnabled,
    shouldShowEachesTemplate,
    shouldShowModal,
    setShowModal,
    addProduct,
    selectedProducts: Object.values(selectedProducts),
    removeProduct,
    isNextDisabled,
    isNextStepLoading,
    dskuQuantities,
    isMultiCasePackEnabled,
    isPrepKittingEnabled,
    destinationType,
    distributionChannel,
    handleReplenishmentBulkUploadSuccess,
    handleProductImportFailure,
    switchConfig,
    handleEachPickingToggle,
  };
};
