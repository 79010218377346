import React, { FC } from "react";
import { OptionProps as SelectOptionProps } from "@deliverr/react-select-v1";
import { Box, IconV2, SelectComponentOption, Text } from "common/components/ui";
import { FlexDiv } from "common/components/FlexDiv";
import { FormattedMessage } from "react-intl";
import { ReplenishmentWarehouseSelectLabels } from "./ReplenishmentWarehouseSelectLabels";
import { ReplenishmentWarehouseOption } from "./useReplenishmentWarehouseSelect";
import styled from "@emotion/styled";
import { LightText } from "returns/create/step/ReturnsText";
import { SimpleWarehouse } from "@deliverr/business-types";

const StyledIcon = styled(IconV2)(`
  margin: 0px;
  padding: 0px;
  align-self: center;
  cursor: pointer;
`);

export const ReplenishmentWarehouseSelectOption: FC<SelectOptionProps> = (props) => {
  const { warehouse, totalSkus, skusWithAvailableInventory }: ReplenishmentWarehouseOption["value"] = props.data.value;
  const doAllSkusHaveInventory = totalSkus === skusWithAvailableInventory;

  return (
    <SelectComponentOption {...props}>
      <Box width={"400px"}>
        <BoldWarehouseName warehouse={warehouse} />

        <Box paddingTop={"S1"} paddingBottom={"S1"}>
          <FlexDiv justifyContent="flex-start">
            <Box paddingTop={"S1"} paddingRight={"S1"}>
              {doAllSkusHaveInventory ? (
                <StyledIcon type="check-circle" color="green" size={"lg"} />
              ) : (
                <StyledIcon type="exclamation-circle" color="orange" size={"lg"} />
              )}
            </Box>

            <LightText>
              {doAllSkusHaveInventory ? (
                <FormattedMessage {...ReplenishmentWarehouseSelectLabels.completeInventoryWarehouse} />
              ) : (
                <FormattedMessage
                  {...ReplenishmentWarehouseSelectLabels.insufficientInventoryWarehouse}
                  values={{
                    totalSkus,
                    skusWithAvailableInventory,
                  }}
                />
              )}
            </LightText>
          </FlexDiv>
        </Box>
      </Box>
    </SelectComponentOption>
  );
};

export const BoldWarehouseName: FC<{ warehouse: SimpleWarehouse }> = ({ warehouse }) => (
  <Text bold>{`${warehouse.address.city}, ${warehouse.address.state}`}</Text>
);
