import { SPThunkAction } from "common/ReduxUtils";
import { SelectedProductData } from "common/components/ProductChooser";
import { getProductChooserKittedDSKUDetails } from "common/components/ProductChooser/ProductChooserSelector";
import { transferCreateAddProduct, transferCreateSetKitComponentOrderItems } from "../actions";

export const addKittedProduct =
  (product: SelectedProductData): SPThunkAction =>
  async (dispatch, getState) => {
    if (!product.isKit || !product.packOf) {
      throw new Error("Product is not a kit or does not have a packOf value");
    }
    const state = getState();
    const productDetails = getProductChooserKittedDSKUDetails(state);
    const kittedProduct = productDetails?.[product.dsku];
    if (kittedProduct) {
      dispatch(transferCreateAddProduct(product.dsku, product));
      for (const component of kittedProduct.kitComponents) {
        dispatch(
          transferCreateSetKitComponentOrderItems({
            dsku: component.dsku,
            packOf: component.packOf,
            unitsPerPack: component.caseQty,
            totalUnits: 0,
          })
        );
      }
    }
  };
