import React, { FC } from "react";
import { Box, Select } from "common/components/ui";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { useReplenishmentWarehouseSelect } from "./useReplenishmentWarehouseSelect";
import { ReplenishmentWarehouseSelectLabels } from "./ReplenishmentWarehouseSelectLabels";

export const ReplenishmentWarehouseSelect: FC = () => {
  const {
    isWarehouseSelectionLoading,
    selectedWarehouseOption,
    warehouseOptions,
    customComponents,
    onWarehouseChange,
    formatOptionLabel,
  } = useReplenishmentWarehouseSelect();

  return (
    <Box paddingTop={"S4"} width={"300px"}>
      <Select
        placeholder={<FormattedMessage {...ReplenishmentWarehouseSelectLabels.selectWarehouse} />}
        options={warehouseOptions}
        value={selectedWarehouseOption}
        formatOptionLabel={formatOptionLabel}
        components={customComponents}
        onChange={onWarehouseChange}
        isLoading={isWarehouseSelectionLoading}
      />
    </Box>
  );
};
