import { storageClient } from "common/clients/instances";
import { logError, logStart } from "Logger";
import { FormattedMessage } from "react-intl";
import { Path } from "paths";
import React from "react";
import { SPThunkAction } from "common/ReduxUtils";
import { StorageTransferStatus } from "common/clients/storage/StorageTransferStatus/StorageTransferStatus";
import { TransferToEcomDetailActionTypes } from "./TransferToEcomDetailActionTypes";
import { getSellerId } from "common/user/UserSelectors";
import history from "BrowserHistory";
import { setTransferDetailFailed } from "./TransferShipmentDetailAction";
import { toast } from "common/components/ui";
import { generatePath } from "react-router-dom";

export const getTransferToEcomDetails =
  (storageTransferOrderId: string): SPThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const ctx = logStart({ fn: "getTransferToEcomDetails", storageTransferOrderId });
    try {
      const sellerId = getSellerId(getState());
      const transferToEcomOrder = (await storageClient.getStorageTransferOrder(sellerId, storageTransferOrderId)).value;

      if (
        [StorageTransferStatus.IN_DRAFT, StorageTransferStatus.ARCHIVED].includes(transferToEcomOrder.internalStatus!)
      ) {
        toast.error(
          <FormattedMessage
            id={"getTransferToEcomDetails.invalidTransfer.message"}
            defaultMessage={"Archived order"}
          />,
          {
            autoClose: 5000,
            toastId: "getTransferToEcomDetails.invalidTransfer",
          }
        );
        history.push(Path.transfersList);
      } else {
        history.push(generatePath(Path.transferDetails, { orderId: transferToEcomOrder.outboundOrderId }));
      }

      dispatch({
        type: TransferToEcomDetailActionTypes.TRANSFER_TO_ECOM_GET_DETAILS_SUCCESS,
        transferToEcomOrder,
      });
    } catch (err) {
      logError(ctx, err, "Error getting transfer to ecom order");
      dispatch(setTransferDetailFailed());
    }
  };
