import { uniq } from "lodash";
import { DskuQty } from "@deliverr/commons-clients";
import { SPThunkAction } from "common/ReduxUtils";
import { getProductChooserKittedDSKUDetails } from "common/components/ProductChooser/ProductChooserSelector";
import { transferCreateRemoveKitComponentOrderItems, transferCreateRemoveProduct } from "../actions";
import { getDSKUQuantitiesForKittedProduct } from "./getDSKUQuantitiesForKittedProduct";
import { selectTransferCreate } from "./TransferCreateSelectors";

export const removeKittedProduct =
  (dsku: string, dskuQuantities: DskuQty[]): SPThunkAction =>
  async (dispatch, getState) => {
    const state = getState();

    const { selectedProducts, kitComponentOrderItems } = selectTransferCreate(state);
    const productDetails = getProductChooserKittedDSKUDetails(state);

    if (selectedProducts && productDetails) {
      const filteredProductDetailsKeys = Object.keys(productDetails).filter((key) => key !== dsku); // Remove the selected kitted product from the product details
      const filteredProductDetails = Object.fromEntries(
        filteredProductDetailsKeys.map((key) => [key, productDetails[key]])
      );

      const updatedDskuQuantities = getDSKUQuantitiesForKittedProduct(dskuQuantities, filteredProductDetails);

      if (selectedProducts[dsku] && productDetails) {
        const dskuWithComponentQuantities = uniq(updatedDskuQuantities);
        dispatch(transferCreateRemoveProduct(dsku));

        const remainingComponentDSKUs = dskuWithComponentQuantities.map(({ dsku: componentDSKU }) => componentDSKU);

        kitComponentOrderItems?.forEach(({ dsku: componentDSKU }) => {
          if (!remainingComponentDSKUs.includes(componentDSKU)) {
            dispatch(transferCreateRemoveKitComponentOrderItems(componentDSKU));
          }
        });
      }
    }
  };
