import { SelectedProductData } from "common/components/ProductChooser/SelectedProductData";
import { isEmpty, isInteger, some } from "lodash/fp";
import { useDispatch, useSelector } from "react-redux";
import { selectTransferSelectedProductsQty } from "transfers/create/store/selectTransferSelectedProductsQty";
import {
  transferCreateRemoveProduct,
  transferCreateSetIsEachPickingOrderFlag,
  transferCreateUpdateProductErrorMessages,
  transferCreateUpdateProductLotNumber,
  transferCreateUpdateProductNumberOfCases,
  transferCreateUpdateProductQty,
} from "transfers/create/actions";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
import { FeatureName, useFeatureOn } from "common/Split";
import { BoxInfoWithCount, estimatePallets } from "@deliverr/commons-utils";
import { ATPDetails } from "common/clients/inventory/ATP/ATPDetails";
import { selectHasKittedProducts } from "transfers/create/store/selectHasKittedProducts";
import { removeKittedProduct } from "transfers/create/store/removeKittedProduct";
import { getProductChooserKittedDSKUDetails } from "common/components/ProductChooser/ProductChooserSelector";
import { find, values } from "lodash";
import { useIntl } from "react-intl";
import { ReplenishmentWarehouseSelectLabels } from "../common/replenishment/warehouse-selection/ReplenishmentWarehouseSelectLabels";

export const useTransferSelectProductQuantityStep = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const {
    originStorageWarehouse,
    errorType,
    destinationType,
    replenishmentError,
    selectedProductsLotNumbers,
    isEachPickingOrder,
  } = useSelector(selectTransferCreate);

  const selectedProductsLotNumbersList = originStorageWarehouse?.dskuQuantities?.reduce<{
    [dsku: string]: ATPDetails[] | undefined;
  }>((acc, { dsku, atpDetails }) => {
    acc[dsku] = atpDetails ?? [];
    return acc;
  }, {});

  const selectedProductsQty = useSelector(selectTransferSelectedProductsQty);
  const isPrepKittingEnabled = useFeatureOn(FeatureName.PrepKittingRSToDTCEnabled);
  const hasOnlyKittedProducts = useSelector(selectHasKittedProducts);
  const kitDskuDetails = useSelector(getProductChooserKittedDSKUDetails);
  const [currentPalletCount, setCurrentPalletCount] = useState(0);

  const isNextDisabled =
    !selectedProductsQty.length ||
    some((selectedProduct: SelectedProductData) => !selectedProduct.qty, selectedProductsQty) ||
    !!find(selectedProductsQty, (val) => !isEmpty(val.errorMessage));

  const isEcomTransfer = useMemo(() => destinationType === TransferCreationTypes.Ecom, [destinationType]);
  const isWholesaleOrderEachPickingOn = useFeatureOn(FeatureName.WholesaleOrderEachPicking);
  const isEachPickingEnabled = destinationType === TransferCreationTypes.Wholesale && isWholesaleOrderEachPickingOn;
  const isKittingEnabled = isPrepKittingEnabled && hasOnlyKittedProducts;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const shouldUseCasePack = !(isEachPickingOrder || isKittingEnabled);

  const updateNumberOfCases = useCallback(
    (dsku: string, numberOfCases: number) => {
      return dispatch(transferCreateUpdateProductNumberOfCases(dsku, isInteger(numberOfCases) ? numberOfCases : 0));
    },
    [dispatch]
  );

  const updateQty = useCallback(
    (dsku: string, totalQuantity: number) => {
      return dispatch(transferCreateUpdateProductQty(dsku, isInteger(totalQuantity) ? totalQuantity : 0));
    },
    [dispatch]
  );

  const handleEachPickingToggle = () => {
    dispatch(transferCreateSetIsEachPickingOrderFlag(!isEachPickingOrder));
  };

  const updateLotNumber = useCallback(
    (dsku: string, lotNumber?: string) => {
      dispatch(
        transferCreateUpdateProductLotNumber(
          dsku,
          isEmpty(lotNumber)
            ? undefined
            : selectedProductsLotNumbersList?.[dsku]?.find((lot) => lot.lotNumber === lotNumber)
        )
      );
      return updateNumberOfCases(dsku, 0);
    },
    [dispatch, selectedProductsLotNumbersList, updateNumberOfCases]
  );

  const removeProduct = (dsku: string) => {
    if (isKittingEnabled && kitDskuDetails?.[dsku]) {
      dispatch(removeKittedProduct(dsku, selectedProductsQty));
      return;
    }
    return dispatch(transferCreateRemoveProduct(dsku));
  };

  useEffect(() => {
    if (selectedProductsQty.length > 0) {
      const boxInfoWithCount: BoxInfoWithCount[] = selectedProductsQty.map((selectedProduct) => {
        return {
          qty: selectedProduct.qty / selectedProduct.caseQty!,
          length: selectedProduct.length!,
          width: selectedProduct.width!,
          weight: selectedProduct.weight!,
          height: selectedProduct.height!,
          lengthUnit: selectedProduct.lengthUnit!,
          weightUnit: selectedProduct.weightUnit!,
        };
      });
      if (isEcomTransfer) {
        const numOfPalletsRequired = estimatePallets(boxInfoWithCount);
        setCurrentPalletCount(numOfPalletsRequired);
      }
    } else {
      setCurrentPalletCount(0);
    }
  }, [isEcomTransfer, selectedProductsQty]);

  useEffect(() => {
    const dskuToErrorMessageMap: { [dsku: string]: string | undefined } = {};

    values(selectedProductsQty).forEach((product) => {
      dskuToErrorMessageMap[product.dsku] =
        product.caseQty! > (product.storageOnHandQty ?? 0)
          ? formatMessage(ReplenishmentWarehouseSelectLabels.availableInventoryLessThanCaseQtyError)
          : undefined;
    });

    dispatch(transferCreateUpdateProductErrorMessages(dskuToErrorMessageMap));
  }, [dispatch, formatMessage, selectedProductsQty]);

  return {
    isEcomTransfer,
    selectedProductsQty,
    originStorageWarehouse,
    updateNumberOfCases,
    isNextDisabled,
    errorType,
    destinationType,
    removeProduct,
    replenishmentError,
    currentPalletCount,
    selectedProductsLotNumbers,
    updateLotNumber,
    selectedProductsLotNumbersList,
    isEachPickingEnabled,
    isEachPickingOrder,
    handleEachPickingToggle,
    updateQty,
    isKittingEnabled,
    shouldUseCasePack,
  };
};
