export const STORAGE_INBOUND_MIN_CASE_WEIGHT_LBS = 1;
export const STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS = 1500;
export const STORAGE_INBOUND_MAX_CASE_LONGEST_IN = 60;
export const STORAGE_INBOUND_ADDITIONAL_MAX_CASE_LONGEST_IN_FOR_SOME_WH = 72;
export const STORAGE_INBOUND_MAX_CASE_MIDDLE_IN = 48;
export const STORAGE_INBOUND_MAX_CASE_SHORTEST_IN = 40;
export const STORAGE_INBOUND_MIN_CASE_MEASUREMENT_IN = 1;
export const STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_LONGEST_IN = 40;
export const STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_MIDDLE_IN = 30;
export const STORAGE_INBOUND_SAFE_MAX_CASE_MEASUREMENT_SHORTEST_IN = 30;
export const STORAGE_INBOUND_MAX_SAFE_CASE_WEIGHT_LBS = 50;
export const INTL_DTC_MAX_CASE_LONGEST_IN = 90;
export const INTL_DTC_MAX_CASE_MIDDLE_IN = 48;
export const INTL_DTC_MAX_CASE_SHORTEST_IN = 40;
export const INTL_DTC_MAX_CASE_WEIGHT_LBS = 1500;
