import { OrderItemDto } from "@deliverr/replenishment-client";
import { SelectedProductData } from "common/components/ProductChooser";
import { mapProductsToMultiPackSkuInventory } from "storage/packUtils/mapProductsToMultiPackSkuInventory";
import { getSelectedProductsWithPackOf } from "./getSelectedProductsWithPackOf";
import { SPThunkAction } from "common/ReduxUtils";
import { StorageWarehouseInventory } from "common/clients/storage/StorageWarehouseInventory";
import { ATPDetails } from "common/clients/inventory/ATP/ATPDetails";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";

export const getShipmentItems =
  (
    selectedProductsQty: SelectedProductData[],
    originStorageWarehouse: StorageWarehouseInventory | undefined,
    selectedProductsLotNumbers?: { [dsku: string]: ATPDetails | undefined }
  ): SPThunkAction<Promise<OrderItemDto[]>> =>
  async (dispatch, getState) => {
    const { isEachPickingOrder } = selectTransferCreate(getState());

    const inventoryBreakdown = mapProductsToMultiPackSkuInventory(
      selectedProductsQty,
      (originStorageWarehouse as any)?.inventoryBreakdown ?? {},
      true,
      selectedProductsLotNumbers
    );

    const selectedProductsQtyWithPackOf = await getSelectedProductsWithPackOf(dispatch, inventoryBreakdown);

    const shipmentItems: OrderItemDto[] = selectedProductsQtyWithPackOf.map(
      ({ dsku, qty, caseQty, packOf, lotNumber, expirationDate }) => ({
        dsku,
        totalUnits: qty ?? 0,
        unitsPerPack: isEachPickingOrder ? 1 : caseQty ?? 1,
        packOf,
        lotNumber,
        expirationDate,
      })
    );

    return shipmentItems;
  };
