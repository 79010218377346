import React, { FC } from "react";

import { DimensionsError } from "./DimensionsError";
import { InputError } from "common/components/InputError";
import { FormattedMessage } from "react-intl";
import { getDimensionErrorLabel } from "inbounds/steps/dimensions/DimensionsAndWeightsTable/DimensionsRowError.labels";
import { FeatureName, useFeatureOn } from "common/Split";

interface DimensionsRowErrorProps {
  error: DimensionsError;
  isIntlDtc?: boolean;
}

export const DimensionsRowError: FC<DimensionsRowErrorProps> = ({ error, isIntlDtc }) => {
  const appearance: "WARNING" | "DANGER" = [DimensionsError.INCOMPLETE, DimensionsError.SIOC].includes(error)
    ? "WARNING"
    : "DANGER";

  const isDimensionOverrideFeatureEnabled = useFeatureOn(FeatureName.InboundRsDimRelaxation);
  return (
    <InputError
      appearance={appearance}
      message={<FormattedMessage {...getDimensionErrorLabel(error, isDimensionOverrideFeatureEnabled, isIntlDtc)} />}
    />
  );
};
