import { NonComplianceShipmentStatus } from "@deliverr/commons-clients";
import { InboundShipment } from "@deliverr/inbound-client";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";
import { defineMessages, MessageDescriptor } from "react-intl";

export const CONFIRMED_STATUSES: string[] = [
  InboundShipmentStatus.PACKAGES_FINAL,
  InboundShipmentStatus.READY_FOR_WAREHOUSE,
  InboundShipmentStatus.CROSS_DOCKING,
  InboundShipmentStatus.IN_PREP,
  InboundShipmentStatus.FORWARDING,
  InboundShipmentStatus.IN_TRANSIT,
  InboundShipmentStatus.ARRIVED,
  InboundShipmentStatus.RECEIVING,
  InboundShipmentStatus.COMPLETED,
  InboundShipmentStatus.WAREHOUSE_APPOINTMENT_NEEDED,
];

export const SHIPMENT_STATUSES_WITH_PACKAGE_DETAILS: InboundShipmentStatus[] = [
  InboundShipmentStatus.NEED_PACKAGE_DETAILS,
  InboundShipmentStatus.PACKAGES_ADDED,
  ...(CONFIRMED_STATUSES as InboundShipmentStatus[]),
];

export const COMPLETED_STATUSES: string[] = [
  InboundShipmentStatus.READY_FOR_WAREHOUSE,
  InboundShipmentStatus.CROSS_DOCKING,
  InboundShipmentStatus.IN_PREP,
  InboundShipmentStatus.FORWARDING,
  InboundShipmentStatus.IN_TRANSIT,
  InboundShipmentStatus.ARRIVED,
  InboundShipmentStatus.RECEIVING,
  InboundShipmentStatus.COMPLETED,
  InboundShipmentStatus.WAREHOUSE_APPOINTMENT_NEEDED,
];

//  Note: undefined has been passed to accomodate for shipping plans that have yet to reach the generate shipping plan stage
export const PRE_TRANSIT_SHIPMENT_STATUSES: (string | undefined)[] = [
  InboundShipmentStatus.NEED_PACKAGE_DETAILS,
  InboundShipmentStatus.PACKAGES_ADDED,
  InboundShipmentStatus.PACKAGES_FINAL,
  InboundShipmentStatus.READY_FOR_WAREHOUSE,
  undefined,
];

export const NON_COMPLIANT_STATUSES: string[] = [NonComplianceShipmentStatus.ACTION_NEEDED];

export const isNonCompliantWithPackages = (shipment: InboundShipment): boolean =>
  NON_COMPLIANT_STATUSES.includes(shipment?.status) && !!shipment?.packagesAddedAt;

export const isConfirmedShipmentStatus = (shipmentStatus: string) => CONFIRMED_STATUSES.includes(shipmentStatus);

export const isCompletedShipmentStatus = (shipmentStatus: string) => COMPLETED_STATUSES.includes(shipmentStatus);

export const isPreShipmentStatus = (shipmentStatus: string) => PRE_TRANSIT_SHIPMENT_STATUSES.includes(shipmentStatus);

export const isEditableStatus = (shipmentStatus?: string) =>
  shipmentStatus == null || SHIPMENT_STATUS_BY_GROUPING.IN_DRAFT.includes(shipmentStatus);

export enum ShipmentStatusGrouping {
  IN_DRAFT = "IN_DRAFT",
  IN_TRANSIT = "IN_TRANSIT",
  READY_TO_SHIP = "READY_TO_SHIP",
  IN_PREP = "IN_PREP",
  FORWARDING = "FORWARDING",
  ARRIVED = "ARRIVED",
  RECEIVING = "RECEIVING",
  ACTION_NEEDED = "ACTION_NEEDED",
  COMPLETED = "COMPLETED",
  WAREHOUSE_APPOINTMENT_NEEDED = "WAREHOUSE_APPOINTMENT_NEEDED",
}

export const SHIPMENT_STATUS_GROUPINGS = Object.values(ShipmentStatusGrouping) as ShipmentStatusGrouping[];

// This is a temporary hacky solution to show the status filter for READY TO SHIP as "Ready to ship or In progress"
// if the accelerate feature flag is enabled. This is to avoid confusion for users who see
// Flexport bookings when the READY TO SHIP status filter is applied.
// In progress is not a real status but something we show in the short term until we can support status updates from flexport
export const getStatusMessageForAccelerate = (status: ShipmentStatusGrouping, isFeatureFlagOn) => {
  if (isFeatureFlagOn && status === ShipmentStatusGrouping.READY_TO_SHIP) {
    return {
      defaultMessage: "Ready to ship or In progress",
      id: `shipmentStatusGrouping.${[ShipmentStatusGrouping.READY_TO_SHIP]}`,
    };
  }
  return SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[status];
};

// These status options are in chronological order and are rendered as such
export const SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT: Record<ShipmentStatusGrouping, MessageDescriptor> = defineMessages({
  [ShipmentStatusGrouping.IN_DRAFT]: {
    defaultMessage: "In draft",
    id: `shipmentStatusGrouping.${[ShipmentStatusGrouping.IN_DRAFT]}`,
  },
  [ShipmentStatusGrouping.READY_TO_SHIP]: {
    defaultMessage: "Ready to ship",
    id: `shipmentStatusGrouping.${[ShipmentStatusGrouping.READY_TO_SHIP]}`,
  },
  [ShipmentStatusGrouping.IN_TRANSIT]: {
    defaultMessage: "In transit",
    id: `shipmentStatusGrouping.${[ShipmentStatusGrouping.IN_TRANSIT]}`,
  },
  [ShipmentStatusGrouping.IN_PREP]: {
    defaultMessage: "With prep",
    id: `shipmentStatusGrouping.${[ShipmentStatusGrouping.IN_PREP]}`,
  },
  [ShipmentStatusGrouping.FORWARDING]: {
    defaultMessage: "Forwarding",
    id: `shipmentStatusGrouping.${[ShipmentStatusGrouping.FORWARDING]}`,
  },
  [ShipmentStatusGrouping.ARRIVED]: {
    defaultMessage: "Arrived",
    id: `shipmentStatusGrouping.${[ShipmentStatusGrouping.ARRIVED]}`,
  },
  [ShipmentStatusGrouping.RECEIVING]: {
    defaultMessage: "Receiving",
    id: `shipmentStatusGrouping.${[ShipmentStatusGrouping.RECEIVING]}`,
  },
  [ShipmentStatusGrouping.ACTION_NEEDED]: {
    defaultMessage: "Action needed",
    id: `shipmentStatusGrouping.${[ShipmentStatusGrouping.ACTION_NEEDED]}`,
  },
  [ShipmentStatusGrouping.COMPLETED]: {
    defaultMessage: "Completed",
    id: `shipmentStatusGrouping.${[ShipmentStatusGrouping.COMPLETED]}`,
  },
  [ShipmentStatusGrouping.WAREHOUSE_APPOINTMENT_NEEDED]: {
    defaultMessage: "Warehouse Appointment Needed",
    id: `shipmentStatusGrouping.${[ShipmentStatusGrouping.WAREHOUSE_APPOINTMENT_NEEDED]}`,
  },
});

export const SHIPMENT_STATUS_DISPLAY_TEXT: Record<InboundShipmentStatus, MessageDescriptor> = {
  [InboundShipmentStatus.NEED_PACKAGE_DETAILS]: SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[ShipmentStatusGrouping.IN_DRAFT],
  [InboundShipmentStatus.PACKAGES_ADDED]: SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[ShipmentStatusGrouping.IN_DRAFT],
  [InboundShipmentStatus.PACKAGES_FINAL]: SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[ShipmentStatusGrouping.IN_DRAFT],
  [InboundShipmentStatus.IN_TRANSIT]: SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[ShipmentStatusGrouping.IN_TRANSIT],
  [InboundShipmentStatus.READY_FOR_WAREHOUSE]:
    SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[ShipmentStatusGrouping.READY_TO_SHIP],
  [InboundShipmentStatus.CROSS_DOCKING]: SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[ShipmentStatusGrouping.FORWARDING],
  [InboundShipmentStatus.IN_PREP]: SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[ShipmentStatusGrouping.IN_PREP],
  [InboundShipmentStatus.FORWARDING]: SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[ShipmentStatusGrouping.FORWARDING],
  [InboundShipmentStatus.ARRIVED]: SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[ShipmentStatusGrouping.ARRIVED],
  [InboundShipmentStatus.RECEIVING]: SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[ShipmentStatusGrouping.RECEIVING],
  [InboundShipmentStatus.COMPLETED]: SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[ShipmentStatusGrouping.COMPLETED],
  [InboundShipmentStatus.WAREHOUSE_APPOINTMENT_NEEDED]:
    SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[ShipmentStatusGrouping.WAREHOUSE_APPOINTMENT_NEEDED],
};

export const SHIPMENT_STATUS_BY_GROUPING: Record<ShipmentStatusGrouping, string[]> = {
  [ShipmentStatusGrouping.IN_DRAFT]: [
    InboundShipmentStatus.NEED_PACKAGE_DETAILS,
    InboundShipmentStatus.PACKAGES_ADDED,
    InboundShipmentStatus.PACKAGES_FINAL,
  ],
  [ShipmentStatusGrouping.IN_TRANSIT]: [InboundShipmentStatus.IN_TRANSIT],
  [ShipmentStatusGrouping.READY_TO_SHIP]: [InboundShipmentStatus.READY_FOR_WAREHOUSE],
  [ShipmentStatusGrouping.IN_PREP]: [InboundShipmentStatus.IN_PREP],
  [ShipmentStatusGrouping.FORWARDING]: [InboundShipmentStatus.FORWARDING, InboundShipmentStatus.CROSS_DOCKING],
  [ShipmentStatusGrouping.ARRIVED]: [InboundShipmentStatus.ARRIVED],
  [ShipmentStatusGrouping.RECEIVING]: [InboundShipmentStatus.RECEIVING],
  [ShipmentStatusGrouping.ACTION_NEEDED]: [NonComplianceShipmentStatus.ACTION_NEEDED],
  [ShipmentStatusGrouping.COMPLETED]: [InboundShipmentStatus.COMPLETED],
  [ShipmentStatusGrouping.WAREHOUSE_APPOINTMENT_NEEDED]: [InboundShipmentStatus.WAREHOUSE_APPOINTMENT_NEEDED],
};
