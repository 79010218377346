import { SPThunkAction } from "common/ReduxUtils";
import { keys, reduce, values } from "lodash";
import {
  transferCreateChooseOriginWarehouse,
  transferCreateSetSelectedProductsCaseQuantities,
} from "transfers/create/actions";
import { StorageWarehouseInventory } from "common/clients/storage/StorageWarehouseInventory";
import { PackSkuQuantity } from "common/clients/storage/Packs/PackSkuQuantity";
import { ATPDetails } from "common/clients/inventory/ATP/ATPDetails";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { ReplenishmentUnifiedInventoryResponse } from "@deliverr/replenishment-client";
import { SelectedProductData } from "common/components/ProductChooser";
import { getUnifiedProducts } from "common/wholesale-common/getUnifiedProducts";
import { getKitInventoryInWarehouse } from "../ReplenishmentWarehouseSelectionUtils";

export const replenishmentSetWarehouseAndProductQuantities = (): SPThunkAction => async (dispatch, getState) => {
  const {
    selectedProducts,
    warehouseSelection: { unifiedInventory, selectedWarehouseId },
  } = selectTransferCreate(getState());

  const storageWarehouse = constructInventoryBreakdownFromUnifiedInventory(
    selectedProducts,
    unifiedInventory!,
    selectedWarehouseId
  );

  await dispatch(transferCreateChooseOriginWarehouse(storageWarehouse));

  const selectedSkus = keys(selectedProducts);
  const packs = await dispatch(getUnifiedProducts(selectedSkus));

  const skuCaseQuantities: Record<string, number> = reduce(
    selectedProducts,
    (acc, { dsku }) => {
      acc[dsku] = packs[dsku].caseQty ?? 1;
      return acc;
    },
    {}
  );

  dispatch(transferCreateSetSelectedProductsCaseQuantities(skuCaseQuantities));
};

const constructInventoryBreakdownFromUnifiedInventory = (
  selectedProducts: Record<string, SelectedProductData>,
  unifiedInventory: ReplenishmentUnifiedInventoryResponse,
  selectedWarehouseId?: string
): StorageWarehouseInventory => {
  const warehouse = unifiedInventory.warehousesWithInventory.find((wh) => wh.id === selectedWarehouseId)!;

  const warehouseInventoryBreakdown = unifiedInventory.rawInventoryBreakdown[warehouse.id];

  const dskuQuantities: StorageWarehouseInventory["dskuQuantities"] = [];
  const inventoryBreakdown: Record<string, PackSkuQuantity[]> = {};

  for (const product of values(selectedProducts)) {
    const dsku = product.dsku;
    const packSkuQuantity: PackSkuQuantity[] = [];

    if (product.isKit) {
      const kitInventory = getKitInventoryInWarehouse(unifiedInventory, warehouse.id, dsku);

      packSkuQuantity.push({
        dsku,
        qty: kitInventory,
      });

      dskuQuantities.push({
        dsku,
        qty: kitInventory,
      });
    } else {
      const productInventory = warehouseInventoryBreakdown[dsku];

      const netQuantity =
        (productInventory?.availableQty ?? 0) + (productInventory?.productInventory?.availableQty ?? 0);

      packSkuQuantity.push({
        dsku,
        qty: productInventory?.availableQty ?? 0,
        atpDetails: productInventory?.atpDetails as ATPDetails[],
      });

      if (productInventory?.productInventory?.availableQty) {
        packSkuQuantity.push({
          dsku,
          qty: productInventory.productInventory.availableQty,
          packOf: productInventory.productInventory.dsku,
          atpDetails: productInventory.productInventory.atpDetails as ATPDetails[],
        });
      }

      dskuQuantities.push({
        dsku,
        qty: netQuantity,
        atpDetails: productInventory?.atpDetails as ATPDetails[],
      });
    }

    inventoryBreakdown[dsku] = packSkuQuantity;
  }

  return {
    warehouse,
    dskuQuantities,
    inventoryBreakdown,
  };
};
