import { sortBy } from "lodash/fp";

import { EntityWithDimensions } from "common/types/EntityWithDimensions";
import {
  STORAGE_INBOUND_ADDITIONAL_MAX_CASE_LONGEST_IN_FOR_SOME_WH,
  STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
  STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
  INTL_DTC_MAX_CASE_LONGEST_IN,
  INTL_DTC_MAX_CASE_MIDDLE_IN,
  INTL_DTC_MAX_CASE_SHORTEST_IN,
} from "storage/inbounds/CaseDimensionsConstants";
import {
  OVERRIDE_STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
  OVERRIDE_STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
  OVERRIDE_STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
} from "storage/inbounds/StorageDimensionsOverrideConstants";

interface MaxDimensions {
  maxShortestSide: number;
  maxMiddleSide: number;
  maxLongestSide: number;
}

export const composeGetIsOverDimensions =
  ({ maxShortestSide, maxMiddleSide, maxLongestSide }: MaxDimensions) =>
  ({ length, width, height }: Exclude<EntityWithDimensions, "weight">) => {
    const sortedDims = sortBy<number>((value) => value, [Number(width ?? 0), Number(height ?? 0), Number(length ?? 0)]);
    return sortedDims[0] > maxShortestSide || sortedDims[1] > maxMiddleSide || sortedDims[2] > maxLongestSide;
  };

export const getInboundCaseDimenLimit = (
  isDimensionOverrideFeatureEnabled: boolean,
  isIntlDtc?: boolean
): MaxDimensions => {
  // Return early if dimension override is enabled
  if (isDimensionOverrideFeatureEnabled) {
    return {
      maxLongestSide: OVERRIDE_STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
      maxMiddleSide: OVERRIDE_STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
      maxShortestSide: OVERRIDE_STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
    };
  }

  return isIntlDtc
    ? {
        maxLongestSide: INTL_DTC_MAX_CASE_LONGEST_IN,
        maxMiddleSide: INTL_DTC_MAX_CASE_MIDDLE_IN,
        maxShortestSide: INTL_DTC_MAX_CASE_SHORTEST_IN,
      }
    : {
        maxLongestSide: STORAGE_INBOUND_ADDITIONAL_MAX_CASE_LONGEST_IN_FOR_SOME_WH,
        maxMiddleSide: STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
        maxShortestSide: STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
      };
};
