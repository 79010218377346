import { pricerClient } from "common/clients/instances";
import { DeliverrOrder, OrderError, OrderSummary, FulfillmentOrderTag } from "@deliverr/fulfillment-order-client";
import { OrderStatus } from "common/clients/orders/DeliverrOrder/OrderStatus";
import { RemovalMethods, shippingMethodsMap } from "./new/ShippingMethods";

export const DISPLAYABLE_ERRORS = [
  OrderError.STOCKOUT,
  OrderError.STOCKOUT_AT_INGESTION,
  OrderError.BAD_ADDRESS,
  OrderError.PARTIALLY_SHIPPED,
  OrderError.DSKU_NEVER_INBOUNDED,
];

export const isDisplayableError = (error: OrderError) => DISPLAYABLE_ERRORS.includes(error);

export const isProcessing = (status: OrderStatus, error?: OrderError) =>
  status === OrderStatus.CREATED || (status === OrderStatus.ERROR && error && !isDisplayableError(error));

export const getServiceLevelForOrder = async (order: OrderSummary) => {
  if (order.tags?.includes(FulfillmentOrderTag.INVENTORY_REMOVAL as any)) {
    return shippingMethodsMap[RemovalMethods.REMOVAL].name;
  }
  const service = await pricerClient.getServiceLevelFromOrder(order);
  return service;
};

export const getServiceLevelsForOrders = async (orders: DeliverrOrder[]) => {
  const serviceLevelsRes = await pricerClient.getServiceLevelFromOrders(orders);
  return orders.map((order, i) =>
    order.tags?.includes(FulfillmentOrderTag.INVENTORY_REMOVAL)
      ? shippingMethodsMap[RemovalMethods.REMOVAL].name
      : serviceLevelsRes[i]
  );
};
/* Shopify marketplaceOrderIds will now be orderNumberDELIVERRSPLITorderId
   This function formats it so the orderNumber will be all that is visible
   on the SellerPortal.
*/
export const formatOrderId = (marketplaceOrderId: string): string => {
  return marketplaceOrderId.includes("DELIVERRSPLIT")
    ? marketplaceOrderId.split("DELIVERRSPLIT")[0]
    : marketplaceOrderId;
};

export const orderIdForDisplay = (marketplaceOrderId: string): string => {
  return marketplaceOrderId.includes("DELIVERRSPLIT")
    ? marketplaceOrderId.replace("DELIVERRSPLIT", "-")
    : marketplaceOrderId;
};
