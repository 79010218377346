import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { SPThunkAction } from "common/ReduxUtils";
import { logError } from "Logger";
import { CreateTransferLoader } from "../../../../../CreateTransferLoader.types";
import { transferCreateSetReplenishmentError, transferCreateSetUnifiedInventory } from "../../../../../actions";
import { batch } from "react-redux";
import { replenishmentClient } from "Clients";
import { EntityError } from "@deliverr/replenishment-client";
import { getFlattenedEntityErrors } from "../../../../fbaV3/commons/FbaV3Utils";
import { InventoryPool } from "@deliverr/business-types";
import { FeatureName, getFeatureSelector } from "common/Split";

export const getReplenishmentUnifiedInventory =
  (dskus: string[]): SPThunkAction =>
  async (dispatch, getState) => {
    batch(() => {
      dispatch(transferCreateSetReplenishmentError({ errors: [] }));
      dispatch(addLoader(CreateTransferLoader.FetchStorageInventoryLoader));
    });

    const isDefaultPoolB2BMerchant = getFeatureSelector(FeatureName.DefaultPoolB2BMerchant)(getState());
    const inventoryPool = isDefaultPoolB2BMerchant ? InventoryPool.DEFAULT : InventoryPool.STORAGE;

    try {
      const { value: replenishmentUnifiedInventory } = await replenishmentClient.getUnifiedAvailableInventory(
        dskus,
        inventoryPool
      );

      dispatch(transferCreateSetUnifiedInventory(replenishmentUnifiedInventory));
    } catch (error) {
      logError({ fn: "getReplenishmentUnifiedInventory" }, error);

      if (error instanceof EntityError) {
        dispatch(
          transferCreateSetReplenishmentError({
            errors: getFlattenedEntityErrors([error]),
            hasError: true,
          })
        );
      } else {
        dispatch(
          transferCreateSetReplenishmentError({
            errors: [
              new EntityError({
                message: error?.message,
                code: error?.code,
              }),
            ],
            hasError: true,
          })
        );
      }
    } finally {
      dispatch(clearLoader(CreateTransferLoader.FetchStorageInventoryLoader));
    }
  };
